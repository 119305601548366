import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from 'contexts/ThemeContext'

export const primaryColor = '#ff9043'
export const light = '#fff'
const dark = '#000e1d'
const darkPrimary = '#5c48a7'
export const textColor = '#212121'

const useTheme = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext)
  const theme = useContext(StyledThemeCopntext)

  if (isDark) {
    theme.colors.text = light
    theme.colors.secondary = primaryColor
    theme.colors.textSubtle = primaryColor
    theme.colors.overlay = primaryColor
    theme.colors.primary = primaryColor
    theme.colors.primaryBright = primaryColor
    theme.colors.background = 'transparent'
    theme.colors.borderColor = `rgba(0,0,0,0.5)`
    theme.colors.input = `rgba(0,0,0,0.5)`
    theme.colors.overlay = `rgba(0,0,0,0.9)`
    theme.card.background = dark
    theme.card.boxShadow = '0 0 30px 0 rgba(0, 0, 0, 0.3)'
    theme.shadows.focus = primaryColor
    theme.button.subtle.color = `#fff`
    theme.button.subtle.background = primaryColor
    theme.button.tertiary.color = `#0b1321`
    theme.button.tertiary.background = primaryColor
    theme.button.tertiary.backgroundHover = 'transparent'
    theme.button.tertiary.border = `1px solid ${primaryColor}`
    theme.button.primary.border = `1px solid ${primaryColor}`
    theme.button.primary.background = primaryColor
    theme.button.primary.color = `#0b1321`
    theme.button.primary.backgroundActive = 'transparent'
    theme.button.primary.backgroundHover = 'transparent'
    theme.button.primary.boxShadow = 'none'
    theme.button.primary.boxShadowActive = 'none'
    theme.button.secondary.border = `2px solid ${primaryColor}`
    theme.button.secondary.borderColorHover = primaryColor
    theme.button.secondary.color = primaryColor
    theme.button.text.color = primaryColor
    theme.modal.background = dark
  } else {
    theme.colors.background = '#ffffff'
    theme.colors.text = textColor
    theme.colors.secondary = primaryColor
    theme.colors.textSubtle = primaryColor
    theme.button.primary.background = primaryColor
    theme.button.primary.backgroundActive = primaryColor
    theme.button.primary.backgroundHover = darkPrimary
    theme.button.primary.boxShadow = 'none'
    theme.button.primary.boxShadowActive = 'none'
    theme.colors.overlay = `rgba(0,0,0,0.9)`
    theme.colors.primary = darkPrimary
    theme.button.text.color = darkPrimary
    theme.button.secondary.color = darkPrimary
    theme.button.secondary.border = `2px solid ${darkPrimary}`
    theme.button.secondary.borderColorHover = primaryColor
    theme.button.tertiary.background = primaryColor
    theme.button.tertiary.color = '#ffffff'
    theme.button.tertiary.backgroundHover = darkPrimary
  }

  return { isDark, toggleTheme, theme }
}

export default useTheme
